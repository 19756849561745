import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { DatabaseService } from 'src/app/shared/database.service';

@Component({
  selector: 'app-single-tournament',
  templateUrl: './single-tournament.component.html',
  styleUrls: ['./single-tournament.component.scss']
})
export class SingleTournamentComponent implements OnInit {

  tournamentID: string;
  title: string;
  allPlayers: any[] = [];
  topTen: any[] = [];
  bellowTen: any[] = [];
  groupsAvailable: any[] = [];
  groupsAvailablePrevious: any[] = [];
  finalScoresTable: any[];
  upcomingWeek: number;
  previousWeek: number;
  upcomingGames: any[];
  previousGames: any[];
  showImage: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private db: DatabaseService
  ) {
    this.route.params.subscribe(
      (params: Params) => {
        this.tournamentID = params.id;
        switch (params.id) {
          case 'conference-league':
            this.tournamentID = 'conference-league';
            this.title = 'Conference League';
            this.db.getTournamentInfo('conference-league').then((val) => {
              this.showImage = val[0].showImage;
              let groups = val[0].groups.sort((a, b) => b.total_points === a.total_points ? a.order - b.order : b.total_points - a.total_points);

              this.finalScoresTable = this.groupBy(groups);
              this.upcomingWeek = Number(val[0].upcoming_week);
              this.previousWeek = Number(val[0].upcoming_week) - 1;

              this.db.getTournamentUpcomingGames('conference-league', this.previousWeek).then((value: any) => {
                this.previousGames = this.groupByPrevious(value.journey)['A'];
              })

              this.db.getTournamentUpcomingGames('conference-league', this.upcomingWeek).then((value: any) => {
                this.upcomingGames = this.groupBy(value.journey)['A'];
              })
            })
            break;

          case 'taca-andre-monteiro':
            this.tournamentID = 'andre-monteiro';
            this.title = 'Taça André Monteiro';
            break;

          case 'liga-europa':
            this.tournamentID = 'liga-europa';
            this.title = 'Liga Europa';
            this.db.getTournamentInfo('liga-europa').then((val) => {
              this.showImage = val[0].showImage;

              let groups = val[0].groups.sort((a, b) => b.total_points === a.total_points ? a.order - b.order : b.total_points - a.total_points);

              this.finalScoresTable = this.groupBy(groups);
              this.upcomingWeek = Number(val[0].upcoming_week);
              this.previousWeek = Number(val[0].upcoming_week) - 1;

              this.db.getTournamentUpcomingGames('liga-europa', this.previousWeek).then((value: any) => {
                this.previousGames = this.groupByPrevious(value.journey);
              })

              this.db.getTournamentUpcomingGames('liga-europa', this.upcomingWeek).then((value: any) => {
                this.upcomingGames = this.groupBy(value.journey);
              })
            })
            break;

          case 'liga-dos-campeoes':
            this.tournamentID = 'liga-campeoes';
            this.title = 'Liga dos Campeões';
            this.db.getTournamentInfo('estrutura-liga-campeoes').then((val) => {
              this.showImage = val[0].showImage;
              let groups = val[0].groups.sort((a, b) => b.total_points === a.total_points ? a.order - b.order : b.total_points - a.total_points);

              this.finalScoresTable = this.groupBy(groups);
              this.upcomingWeek = Number(val[0].upcoming_week);
              this.previousWeek = Number(val[0].upcoming_week) - 1;

              this.db.getTournamentUpcomingGames('estrutura-liga-campeoes', this.previousWeek).then((value: any) => {
                this.previousGames = this.groupByPrevious(value.journey);
              })

              this.db.getTournamentUpcomingGames('estrutura-liga-campeoes', this.upcomingWeek).then((value: any) => {
                this.upcomingGames = this.groupBy(value.journey);
              })
            })

            break;
        }
        //this.db.getTournament(this.tournamentID)

      }
    );
  }

  ngOnInit(): void {
    if (this.tournamentID !== 'andre-monteiro' && this.tournamentID !== 'conference-league') {
      this.db.getTop10Tournament(this.tournamentID).then((players: any[]) => {
        this.allPlayers = players;
      })

    } else if (this.tournamentID === 'andre-monteiro') {
      this.db.getListByGeneralResults('ricardo-silva', 'total_points')
        .then((results: any) => {
          this.allPlayers = results.filter(el => el.status === 'active');
        })
    }

    //data2.forEach(d => {
    //this.db.setTournamentStructure('estrutura-liga-campeoes', {journey: week4}, 'week4');

    //})
  }


  groupBy(data: any) {

    //let resultArray: any[] = [];
    let resByType = data.reduce((result: any, obj: any) => {

      (result[obj['group']] = result[obj['group']] || []).push(obj);

      return result;

    }, {});


    this.groupsAvailable = Object.keys(resByType)

    return resByType;
  }

  groupByPrevious(data: any) {

    //let resultArray: any[] = [];


    let resByType = data.reduce((result: any, obj: any) => {

      (result[obj['group']] = result[obj['group']] || []).push(obj);

      return result;

    }, {});

    this.groupsAvailablePrevious = Object.keys(resByType);
    return resByType;



  }

  /** DATA TO SAVE ON DB
   * 
   * let data = [
      { player: " Sérgio Silva", team: " Ajax Limpa- Tudo" },
      { player: "Luís Miguel", team: "Man Celos FC" },
      { player: "Fabio Hugo", team: " MecosFC" },
      { player: "Diogo Magalhães", team: "Vila de Cadiz" },
      { player: "Miguel Monteiro", team: "mike Monteiro" },
      { player: "Ricardo Leitão", team: "Lages Team" },
      { player: "Tiago Nogueira", team: " TiagoTiago" },
      { player: "Ângelo Santana", team: "JOHNNY DOGS UTD" },
      { player: "Rafael Couto", team: " vi(m)ver" },
      { player: "José Alves", team: " Dragon_86" },
      { player: "Daniel Sousa", team: "Welsby FC" },
      { player: "Julio Pinto", team: "Maureles UTD" },
      { player: "Sérgio Mauro", team: "Apóstolos de Schmidt" },
      { player: "André Monteiro", team: " NWN" },
      { player: "Pedro Soares", team: "Popcorn team" },
      { player: "Joao Abreu", team: "vim pela cerveja 2.0" },
      { player: "Valter Pinto", team: " ValterPinto20" },
      { player: "Rafael Teixeira", team: "Medallo FC" },
      { player: "Tony Ferguson", team: "Arsenal top two LOL" },
      { player: "Zé Carlos Barros", team: " Tripé" },
      { player: "João Cabral", team: "Tapadinha City F.C" },
      { player: "Mauricio Barros", team: "Ott tanak" },
      { player: "Arlindo Vasconcelos", team: "AMRV Unt" },
      { player: "Bernardo Neto", team: " BN" },
      { player: "João Monteiro", team: "VARZIELA FC" },
      { player: "Bruno Serra", team: "Not Found! Error 404" },
      { player: "José Ferreira", team: "Sara Jay - Jay Okocha" },
      { player: "Diogo Monteiro", team: "Los Palmeras" },
      { player: "Bruno Moreira", team: "Raul De Tomas" },
      { player: "Nuno Ferreira", team: "Era uma vez FC" },
      { player: "Fábio Sanhudo", team: " Brigate_Fs1893" },
      { player: "Pedro Santana", team: "Cleveland Browns" },
      { player: "Eduardo Vieira", team: " NorwegianForce" },
      { player: "Joel Machado", team: " Recebaaaa" },
      { player: "Bruno Ferraz", team: " F.C.Canelas" },
      { player: "Miguel Monteiro", team: "Estou só a ver" },
      { player: "Marcos Queirós", team: "O 3 Pernas" },
      { player: "João Soares", team: " PepSemTola" },
      { player: "Luis Rodrigues", team: "Change Name" },
      { player: "João Aguiar", team: "Bairros FC" },
      { player: "André Ferreira", team: " Illuminati" },
      { player: "Roee Lotan", team: "Pastéis de Nata" },
      { player: "Tiago Sousa", team: " 1893" },
      { player: "José Nuno Ferreira", team: "SR MÁRIO SARAIVA" },
      { player: "José Mário Lopes Martins", team: " 7831XF F.C." },
      { player: "Rúben Ferraz", team: " Ruben10" },
      { player: "Francisco Ferreira", team: "Maiores do mundo" },
      { player: "Ricardo Silva", team: "Silva ð" },
      { player: "Bruno Teixeira", team: "Quim Portuga" },
      { player: "Hugo Teixeira", team: " tnt" },
      { player: "Rui Barros", team: " Rb75" },
      { player: "João Carneiro", team: " Carnieri2" },
      { player: "Vincent Rios", team: " Guiri" },
      { player: "Quim Costa", team: " pilasésumnabo" },
      { player: "José Luís Sousa", team: "Arsoles FC" },
      { player: "luiggi pereira", team: " luiggi" },
      { player: "Filipe Coutinho", team: " ghost1nteam" },
      { player: "José Francisco Sousa", team: "Borussia Notre - Dame" },
      { player: "Jorge Nogueira", team: "JEMN FC" },
      { player: "Fábio Ribeiro", team: "FC Drinkwater" },
      { player: "hugo teixeira", team: " Nunez + 10" },
      { player: "helder ribeiro", team: " tangerinamecanica" },
      { player: "Miguel Moreira", team: "Atlético de Marte" },
      { player: "Rui Pereira", team: " RP32" },
      { player: "Manuel Ferreira", team: "Tenho Sentimentos FC" },
      { player: "Bessa Joao", team: " joaobessa" },
      { player: "Eugénio Sousa", team: " OrnitologoPortista" },
      { player: "Artur Sousa", team: " Arturito" },
      { player: "Paul Tait", team: "Church of Chunks" },
      { player: "Márcio Teixeira", team: "No Room For Maguires" },
      { player: "José Pedro Queiroz", team: "Titan Team" },
      { player: "José Barão", team: " Muchachos" },
      { player: "Joao Santos", team: " partemtudo" },
      { player: "Vitor Mendes", team: "CÔNDE FC" },
      { player: "Tiago Castro", team: "Rapa'eito" },
      { player: "Ricardo Ferraz", team: " Dragões" },
      { player: "Diogo Martins", team: "BAR D'PALHA" },
      { player: "Rui Diogo", team: "Sanusi ZM" },
      { player: "Jackie Hughes", team: " UnionJackie" },
      { player: "Leonardo Moreira", team: "Bayern da Esperança" },
    ];

    let data2 = [
      { player: "player,team,points,group", status: "active" },
      { player: "Diogo Monteiro", team: "Los Palmeras", total_points: 0, group: "A", status: "active" },
      { player: "Francisco Ferreira", team: "Maiores do mundo", total_points: 0, group: "A", status: "active" },
      { player: "Diogo Martins", team: "BAR D'PALHA", total_points: 0, group: "A", status: "active" },
      { player: "Quim Costa", team: "pilasésumnabo", total_points: 0, group: "A", status: "active" },
      { player: "Sérgio Mauro", team: "Apóstolos de Schmidt", total_points: 0, group: "A", status: "active" },
      { player: "Joao Abreu", team: "vim pela cerveja 2.0", total_points: 0, group: "A", status: "active" },
      { player: "Roee Lotan", team: "Pastéis de Nata", total_points: 0, group: "A", status: "active" },
      { player: "Rafael Couto", team: "vi(m)ver", total_points: 0, group: "A", status: "active" },
      { player: "José Alves", team: "Dragon_86", total_points: 0, group: "A", status: "active" },
      { player: "Diogo Magalhães", team: "Vila de Cadiz", total_points: 0, group: "A", status: "active" },
      { player: "Ricardo Leitão", team: "Lages Team", total_points: 0, group: "B", status: "active" },
      { player: "Pedro Soares", team: "Popcorn team", total_points: 0, group: "B", status: "active" },
      { player: "Luís Miguel", team: "Man Celos FC", total_points: 0, group: "B", status: "active" },
      { player: "Ricardo Ferraz", team: "Dragões", total_points: 0, group: "B", status: "active" },
      { player: "Bruno Ferraz", team: "F.C. Canelas", total_points: 0, group: "B", status: "active" },
      { player: "Tiago Castro", team: "Rapa'eito", total_points: 0, group: "B", status: "active" },
      { player: "Fábio Ribeiro", team: "FC Drinkwater", total_points: 0, group: "B", status: "active" },
      { player: "Julio Pinto", team: "Maureles UTD", total_points: 0, group: "B", status: "active" },
      { player: "Sérgio Silva", team: "Ajax Limpa-Tudo", total_points: 0, group: "B", status: "active" },
      { player: "Miguel Monteiro", team: "mike Monteiro", total_points: 0, group: "B", status: "active" },
      { player: "Tiago Sousa", team: "1893", total_points: 0, group: "C", status: "active" },
      { player: "Bessa Joao", team: "joaobessa", total_points: 0, group: "C", status: "active" },
      { player: "Rafael Teixeira", team: "Medallo FC", total_points: 0, group: "C", status: "active" },
      { player: "Joel Machado", team: "Recebaaaa", total_points: 0, group: "C", status: "active" },
      { player: "Marcos Queirós", team: "O 3 Pernas", total_points: 0, group: "C", status: "active" },
      { player: "José Nuno Ferreira", team: "SR MÁRIO SARAIVA", total_points: 0, group: "C", status: "active" },
      { player: "helder ribeiro", team: "tangerinamecanica", total_points: 0, group: "C", status: "active" },
      { player: "Zé Carlos Barros", team: "Tripé", total_points: 0, group: "C", status: "active" },
      { player: "João Aguiar", team: "Bairros FC", total_points: 0, group: "C", status: "active" },
      { player: "hugo teixeira", team: "Nunez+10", total_points: 0, group: "C", status: "active" },
      { player: "Ricardo Silva", team: "Silva ð", total_points: 0, group: "D", status: "active" },
      { player: "Vincent Rios", team: "Guiri", total_points: 0, group: "D", status: "active" },
      { player: "Manuel Ferreira", team: "Tenho Sentimentos FC", total_points: 0, group: "D", status: "active" },
      { player: "Hugo Teixeira", team: "tnt", total_points: 0, group: "D", status: "active" },
      { player: "José Francisco Sousa", team: "Borussia Notre-Dame", total_points: 0, group: "D", status: "active" },
      { player: "João Cabral", team: "Tapadinha City F.C", total_points: 0, group: "D", status: "active" },
      { player: "Tiago Nogueira", team: "TiagoTiago", total_points: 0, group: "D", status: "active" },
      { player: "Tony Ferguson", team: "Arsenal top two LOL", total_points: 0, group: "D", status: "active" },
      { player: "Márcio Teixeira", team: "No Room For Maguires", total_points: 0, group: "D", status: "active" },
      { player: "Bruno Teixeira", team: "Quim Portuga", total_points: 0, group: "D", status: "active" },
      { player: "João Soares", team: "PepSemTola", total_points: 0, group: "E", status: "active" },
      { player: "José Luís Sousa", team: "Arsoles FC", total_points: 0, group: "E", status: "active" },
      { player: "José Mário Lopes Martins", team: "7831XF F.C.", total_points: 0, group: "E", status: "active" },
      { player: "Arlindo Vasconcelos", team: "AMRV Unt", total_points: 0, group: "E", status: "active" },
      { player: "Bernardo Neto", team: "BN", total_points: 0, group: "E", status: "active" },
      { player: "Artur Sousa", team: "Arturito", total_points: 0, group: "E", status: "active" },
      { player: "Eduardo Vieira", team: "NorwegianForce", total_points: 0, group: "E", status: "active" },
      { player: "Nuno Ferreira", team: "Era uma vez FC", total_points: 0, group: "E", status: "active" },
      { player: "José Barão", team: "Muchachos", total_points: 0, group: "E", status: "active" },
      { player: "Filipe Coutinho", team: "ghost1nteam", total_points: 0, group: "E", status: "active" },
      { player: "João Monteiro", team: "VARZIELA FC", total_points: 0, group: "F", status: "active" },
      { player: "Rui Barros", team: "Rb75", total_points: 0, group: "F", status: "active" },
      { player: "João Carneiro", team: "Carnieri2", total_points: 0, group: "F", status: "active" },
      { player: "Bruno Moreira", team: "Raul De Tomas", total_points: 0, group: "F", status: "active" },
      { player: "Eugénio Sousa", team: "OrnitologoPortista", total_points: 0, group: "F", status: "active" },
      { player: "José Ferreira", team: "Sara Jay-Jay Okocha", total_points: 0, group: "F", status: "active" },
      { player: "Rui Diogo", team: "Sanusi ZM", total_points: 0, group: "F", status: "active" },
      { player: "Luis Rodrigues", team: "Change Name", total_points: 0, group: "F", status: "active" },
      { player: "Jackie Hughes", team: "UnionJackie", total_points: 0, group: "F", status: "active" },
      { player: "Fábio Sanhudo", team: "Brigate_Fs1893", total_points: 0, group: "F", status: "active" },
      { player: "Miguel Moreira", team: "Atlético de Marte", total_points: 0, group: "G", status: "active" },
      { player: "Miguel Monteiro", team: "Estou só a ver", total_points: 0, group: "G", status: "active" },
      { player: "José Pedro Queiroz", team: "Titan Team", total_points: 0, group: "G", status: "active" },
      { player: "Pedro Santana", team: "Cleveland Browns", total_points: 0, group: "G", status: "active" },
      { player: "Joao Santos", team: "partemtudo", total_points: 0, group: "G", status: "active" },
      { player: "Rúben Ferraz", team: "Ruben10", total_points: 0, group: "G", status: "active" },
      { player: "Valter Pinto", team: "ValterPinto20", total_points: 0, group: "G", status: "active" },
      { player: "Leonardo Moreira", team: "Bayern da Esperança", total_points: 0, group: "G", status: "active" },
      { player: "André Monteiro", team: "NWN", total_points: 0, group: "G", status: "active" },
      { player: "Rui Pereira", team: "RP32", total_points: 0, group: "G", status: "active" },
      { player: "Ângelo Santana", team: "JOHNNY DOGS UTD", total_points: 0, group: "H", status: "active" },
      { player: "Paul Tait", team: "Church of Chunks", total_points: 0, group: "H", status: "active" },
      { player: "Fabio Hugo", team: "MecosFC", total_points: 0, group: "H", status: "active" },
      { player: "Daniel Sousa", team: "Welsby FC", total_points: 0, group: "H", status: "active" },
      { player: "Bruno Serra", team: "Not Found! Error 404", total_points: 0, group: "H", status: "active" },
      { player: "Mauricio Barros", team: "Ott tanak", total_points: 0, group: "H", status: "active" },
      { player: "luiggi pereira", team: "luiggi", total_points: 0, group: "H", status: "active" },
      { player: "Jorge Nogueira", team: "JEMN FC", total_points: 0, group: "H", status: "active" },
      { player: "André Ferreira", team: "Illuminati", total_points: 0, group: "H", status: "active" },
      { player: "Vitor Mendes", team: "CÔNDE FC", total_points: 0, group: "H", status: "active" },

    ]

    let week2 = [
      //group A
      {
        home:
        {
          team: "Apóstolos de Schmidt", points: 0
        },
        away:
        {
          team: "vim pela cerveja 2.0", points: 0
        },
        group: "A"
      },
      {
        home:
        {
          team: "Vila de Cadiz", points: 0
        },
        away:
        {
          team: "Maiores do mundo", points: 0
        },
        group: "A"
      },
      {
        home:
        {
          team: "pilasésumnabo", points: 0
        },
        away:
        {
          team: "vi(m)ver", points: 0
        },
        group: "A"
      },
      {
        home:
        {
          team: "Dragon_86", points: 0
        },
        away:
        {
          team: "Pastéis de Nata", points: 0
        },
        group: "A"
      },
      {
        home:
        {
          team: "BAR D'PALHA", points: 0
        },
        away:
        {
          team: "Los Palmeras", points: 0
        },
        group: "A"
      },
      //group B
      {
        home:
        {
          team: "Lages Team", points: 0
        },
        away:
        {
          team: "Rapa'eito", points: 0
        },
        group: "B"
      },
      {
        home:
        {
          team: "Popcorn team", points: 0
        },
        away:
        {
          team: "FC Drinkwater", points: 0
        },
        group: "B"
      },
      {
        home:
        {
          team: "Man Celos FC", points: 0
        },
        away:
        {
          team: "Maureles UTD", points: 0
        },
        group: "B"
      },
      {
        home:
        {
          team: "Dragões", points: 0
        },
        away:
        {
          team: "Ajax Limpa-Tudo", points: 0
        },
        group: "B"
      },
      {
        home:
        {
          team: "F.C. Canelas", points: 0
        },
        away:
        {
          team: "mike Monteiro", points: 0
        },
        group: "B"
      },
      //group C
      {
        home:
        {
          team: "1893", points: 0
        },
        away:
        {
          team: "SR MÁRIO SARAIVA", points: 0
        },
        group: "C"
      },
      {
        home:
        {
          team: "joaobessa", points: 0
        },
        away:
        {
          team: "tangerinamecanica", points: 0
        },
        group: "C"
      },
      {
        home:
        {
          team: "Medallo FC", points: 0
        },
        away:
        {
          team: "Tripé", points: 0
        },
        group: "C"
      },
      {
        home:
        {
          team: "Recebaaaa", points: 0
        },
        away:
        {
          team: "Bairros FC", points: 0
        },
        group: "C"
      },
      {
        home:
        {
          team: "O 3 Pernas", points: 0
        },
        away:
        {
          team: "Nunez+10", points: 62
        },
        group: "C"
      },
      //group D
      {
        home:
        {
          team: "Silva", points: 0
        },
        away:
        {
          team: "Tapadinha City F.C", points: 0
        },
        group: "D"
      },
      {
        home:
        {
          team: "Guiri", points: 0
        },
        away:
        {
          team: "TiagoTiago", points: 0
        },
        group: "D"
      },
      {
        home:
        {
          team: "Tenho Sentimentos FC", points: 0
        },
        away:
        {
          team: "Arsenal top two LOL", points: 0
        },
        group: "D"
      },
      {
        home:
        {
          team: "tnt", points: 0
        },
        away:
        {
          team: "No Room For Maguires", points: 0
        },
        group: "D"
      },
      {
        home:
        {
          team: "Borussia Notre-Dame", points: 0
        },
        away:
        {
          team: "Quim Portuga", points: 0
        },
        group: "D"
      },
      //group E
      {
        home:
        {
          team: "PepSemTola", points: 0
        },
        away:
        {
          team: "Arturito", points: 0
        },
        group: "E"
      },
      {
        home:
        {
          team: "Arsoles FC", points: 0
        },
        away:
        {
          team: "NorwegianForce", points: 0
        },
        group: "E"
      },
      {
        home:
        {
          team: "7831XF F.C.", points: 0
        },
        away:
        {
          team: "Era uma vez FC", points: 0
        },
        group: "E"
      },
      {
        home:
        {
          team: "AMRV Unt", points: 0
        },
        away:
        {
          team: "Muchachos", points: 0
        },
        group: "E"
      },
      {
        home:
        {
          team: "BN", points: 0
        },
        away:
        {
          team: "ghost1nteam", points: 0
        },
        group: "E"
      },
      //group F
      {
        home:
        {
          team: "VARZIELA FC", points: 0
        },
        away:
        {
          team: "Sara Jay-Jay Okocha", points: 0
        },
        group: "F"
      },
      {
        home:
        {
          team: "Rb75", points: 0
        },
        away:
        {
          team: "Sanusi ZM", points: 0
        },
        group: "F"
      },
      {
        home:
        {
          team: "Carnieri2", points: 0
        },
        away:
        {
          team: "UnionJackie", points: 0
        },
        group: "F"
      },
      {
        home:
        {
          team: "Raul De Tomas", points: 0
        },
        away:
        {
          team: "Change Name", points: 0
        },
        group: "F"
      },
      {
        home:
        {
          team: "OrnitologoPortista", points: 0
        },
        away:
        {
          team: "Brigate_Fs1893", points: 0
        },
        group: "D"
      },
      //group G
      {
        home:
        {
          team: "Atlético de Marte", points: 0
        },
        away:
        {
          team: "Ruben10", points: 0
        },
        group: "G"
      },
      {
        home:
        {
          team: "Estou só a ver", points: 0
        },
        away:
        {
          team: "ValterPinto20", points: 0
        },
        group: "G"
      },
      {
        home:
        {
          team: "Titan Team", points: 0
        },
        away:
        {
          team: "Bayern da Esperança", points: 0
        },
        group: "G"
      },
      {
        home:
        {
          team: "Cleveland Browns", points: 0
        },
        away:
        {
          team: "NWN", points: 0
        },
        group: "G"
      },
      {
        home:
        {
          team: "partemtudo", points: 0
        },
        away:
        {
          team: "RP32", points: 0
        },
        group: "G"
      },
      //group H
      {
        home:
        {
          team: "JOHNNY DOGS UTD", points: 0
        },
        away:
        {
          team: "Ott tanak", points: 0
        },
        group: "H"
      },
      {
        home:
        {
          team: "Church of Chunks", points: 0
        },
        away:
        {
          team: "luiggi", points: 0
        },
        group: "H"
      },
      {
        home:
        {
          team: "MecosFC", points: 0
        },
        away:
        {
          team: "JEMN FC", points: 0
        },
        group: "H"
      },
      {
        home:
        {
          team: "Welsby FC", points: 0
        },
        away:
        {
          team: "Illuminati", points: 0
        },
        group: "H"
      },
      {
        home:
        {
          team: "Not Found! Error 404", points: 0
        },
        away:
        {
          team: "CÔNDE FC", points: 0
        },
        group: "H"
      },
    ];

    let week3 = [
      //group A
      {
        home:
        {
          team: "Apóstolos de Schmidt", points: 0
        },
        away:
        {
          team: "pilasésumnabo", points: 0
        },
        group: "A"
      },
      {
        home:
        {
          team: "Vila de Cadiz", points: 0
        },
        away:
        {
          team: "BAR D'PALHA", points: 0
        },
        group: "A"
      },
      {
        home:
        {
          team: "Dragon_86", points: 0
        },
        away:
        {
          team: "Maiores do mundo", points: 0
        },
        group: "A"
      },
      {
        home:
        {
          team: "vim pela cerveja 2.0", points: 0
        },
        away:
        {
          team: "Pastéis de Nata", points: 0
        },
        group: "A"
      },
      {
        home:
        {
          team: "vi(m)ver", points: 0
        },
        away:
        {
          team: "Los Palmeras", points: 0
        },
        group: "A"
      },
      //group B
      {
        home:
        {
          team: "Lages Team", points: 0
        },
        away:
        {
          team: "Man Celos FC", points: 0
        },
        group: "B"
      },
      {
        home:
        {
          team: "Popcorn team", points: 0
        },
        away:
        {
          team: "F.C. Canelas", points: 0
        },
        group: "B"
      },
      {
        home:
        {
          team: "Dragões", points: 0
        },
        away:
        {
          team: "FC Drinkwater", points: 0
        },
        group: "B"
      },
      {
        home:
        {
          team: "Rapa'eito", points: 0
        },
        away:
        {
          team: "Ajax Limpa-Tudo", points: 0
        },
        group: "B"
      },
      {
        home:
        {
          team: "Maureles UTD", points: 0
        },
        away:
        {
          team: "mike Monteiro", points: 0
        },
        group: "B"
      },
      //group C
      {
        home:
        {
          team: "1893", points: 0
        },
        away:
        {
          team: "Medallo FC", points: 0
        },
        group: "C"
      },
      {
        home:
        {
          team: "joaobessa", points: 0
        },
        away:
        {
          team: "O 3 Pernas", points: 0
        },
        group: "C"
      },
      {
        home:
        {
          team: "Recebaaaa", points: 0
        },
        away:
        {
          team: "tangerinamecanica", points: 0
        },
        group: "C"
      },
      {
        home:
        {
          team: "SR MÁRIO SARAIVA", points: 0
        },
        away:
        {
          team: "Bairros FC", points: 0
        },
        group: "C"
      },
      {
        home:
        {
          team: "Tripé", points: 0
        },
        away:
        {
          team: "Nunez+10", points: 62
        },
        group: "C"
      },
      //group D
      {
        home:
        {
          team: "Silva", points: 0
        },
        away:
        {
          team: "Tenho Sentimentos FC", points: 0
        },
        group: "D"
      },
      {
        home:
        {
          team: "Guiri", points: 0
        },
        away:
        {
          team: "Borussia Notre-Dame", points: 0
        },
        group: "D"
      },
      {
        home:
        {
          team: "tnt", points: 0
        },
        away:
        {
          team: "TiagoTiago", points: 0
        },
        group: "D"
      },
      {
        home:
        {
          team: "Tapadinha City F.C", points: 0
        },
        away:
        {
          team: "No Room For Maguires", points: 0
        },
        group: "D"
      },
      {
        home:
        {
          team: "Arsenal top two LOL", points: 0
        },
        away:
        {
          team: "Quim Portuga", points: 0
        },
        group: "D"
      },
      //group E
      {
        home:
        {
          team: "PepSemTola", points: 0
        },
        away:
        {
          team: "7831XF F.C.", points: 0
        },
        group: "E"
      },
      {
        home:
        {
          team: "Arsoles FC", points: 0
        },
        away:
        {
          team: "BN", points: 0
        },
        group: "E"
      },
      {
        home:
        {
          team: "AMRV Unt", points: 0
        },
        away:
        {
          team: "NorwegianForce", points: 0
        },
        group: "E"
      },
      {
        home:
        {
          team: "Arturito", points: 0
        },
        away:
        {
          team: "Muchachos", points: 0
        },
        group: "E"
      },
      {
        home:
        {
          team: "Era uma vez FC", points: 0
        },
        away:
        {
          team: "ghost1nteam", points: 0
        },
        group: "E"
      },
      //group F
      {
        home:
        {
          team: "VARZIELA FC", points: 0
        },
        away:
        {
          team: "Carnieri2", points: 0
        },
        group: "F"
      },
      {
        home:
        {
          team: "Rb75", points: 0
        },
        away:
        {
          team: "OrnitologoPortista", points: 0
        },
        group: "F"
      },
      {
        home:
        {
          team: "Raul De Tomas", points: 0
        },
        away:
        {
          team: "Sanusi ZM", points: 0
        },
        group: "F"
      },
      {
        home:
        {
          team: "Sara Jay-Jay Okocha", points: 0
        },
        away:
        {
          team: "Change Name", points: 0
        },
        group: "F"
      },
      {
        home:
        {
          team: "UnionJackie", points: 0
        },
        away:
        {
          team: "Brigate_Fs1893", points: 0
        },
        group: "D"
      },
      //group G
      {
        home:
        {
          team: "Atlético de Marte", points: 0
        },
        away:
        {
          team: "Titan Team", points: 0
        },
        group: "G"
      },
      {
        home:
        {
          team: "Estou só a ver", points: 0
        },
        away:
        {
          team: "partemtudo", points: 0
        },
        group: "G"
      },
      {
        home:
        {
          team: "Cleveland Browns", points: 0
        },
        away:
        {
          team: "ValterPinto20", points: 0
        },
        group: "G"
      },
      {
        home:
        {
          team: "Ruben10", points: 0
        },
        away:
        {
          team: "NWN", points: 0
        },
        group: "G"
      },
      {
        home:
        {
          team: "Bayern da Esperança", points: 0
        },
        away:
        {
          team: "RP32", points: 0
        },
        group: "G"
      },
      //group H
      {
        home:
        {
          team: "JOHNNY DOGS UTD", points: 0
        },
        away:
        {
          team: "MecosFC", points: 0
        },
        group: "H"
      },
      {
        home:
        {
          team: "Church of Chunks", points: 0
        },
        away:
        {
          team: "Not Found! Error 404", points: 0
        },
        group: "H"
      },
      {
        home:
        {
          team: "Welsby FC", points: 0
        },
        away:
        {
          team: "luiggi", points: 0
        },
        group: "H"
      },
      {
        home:
        {
          team: "Ott tanak", points: 0
        },
        away:
        {
          team: "Illuminati", points: 0
        },
        group: "H"
      },
      {
        home:
        {
          team: "JEMN FC", points: 0
        },
        away:
        {
          team: "CÔNDE FC", points: 0
        },
        group: "H"
      },
    ];

    


     let data = [
      { player: "Diogo Monteiro", team: "Los Palmeras", total_points: 0, status: "active", journeys: [{ score: 68, week: "1" }, { score: 69, week: "2" }, { score: 58, week: "3" }] },
      { player: "Francisco Ferreira", team: "Maiores do mundo", total_points: 0, status: "active", journeys: [{ score: 61, week: "1" }, { score: 63, week: "2" }, { score: 55, week: "3" }] },
      { player: "Diogo Martins", team: "BAR D'PALHA", total_points: 0, status: "active", journeys: [{ score: 42, week: "1" }, { score: 98, week: "2" }, { score: 38, week: "3" }] },
      { player: "Quim Costa", team: "pilasésumnabo", total_points: 0, status: "active", journeys: [{ score: 61, week: "1" }, { score: 57, week: "2" }, { score: 40, week: "3" }] },
      { player: "Sérgio Mauro", team: "Apóstolos de Schmidt", total_points: 0, status: "active", journeys: [{ score: 73, week: "1" }, { score: 67, week: "2" }, { score: 39, week: "3" }] },
      { player: "Joao Abreu", team: "vim pela cerveja 2.0", total_points: 0, status: "active", journeys: [{ score: 72, week: "1" }, { score: 58, week: "2" }, { score: 55, week: "3" }] },
      { player: "Roee Lotan", team: "Pastéis de Nata", total_points: 0, status: "active", journeys: [{ score: 63, week: "1" }, { score: 68, week: "2" }, { score: 44, week: "3" }] },
      { player: "Rafael Couto", team: "vi(m)ver", total_points: 0, status: "active", journeys: [{ score: 76, week: "1" }, { score: 42, week: "2" }, { score: 52, week: "3" }] },
      { player: "José Alves", team: "Dragon_86", total_points: 0, status: "inactive", journeys: [{ score: 75, week: "1" }] },
      { player: "Diogo Magalhães", team: "Vila de Cadiz", total_points: 0, status: "active", journeys: [{ score: 83, week: "1" }, { score: 66, week: "2" }, { score: 54, week: "3" }] },
      { player: "Ricardo Leitão", team: "Lages Team", total_points: 0, status: "active", journeys: [{ score: 79, week: "1" }, { score: 67, week: "2" }, { score: 60, week: "3" }] },
      { player: "Pedro Soares", team: "Popcorn team", total_points: 0, status: "active", journeys: [{ score: 72, week: "1" }, { score: 47, week: "2" }, { score: 63, week: "3" }] },
      { player: "Luís Miguel", team: "Man Celos FC", total_points: 0, status: "active", journeys: [{ score: 84, week: "1" }, { score: 73, week: "2" }, { score: 41, week: "3" }] },
      { player: "Ricardo Ferraz", team: "Dragões", total_points: 0, status: "inactive", journeys: [{ score: 43, week: "1" }] },
      { player: "Bruno Ferraz", team: "F.C. Canelas", total_points: 0, status: "active", journeys: [{ score: 64, week: "1" }, { score: 61, week: "2" }, { score: 45, week: "3" }] },
      { player: "Tiago Castro", team: "Rapa'eito", total_points: 0, status: "active", journeys: [{ score: 43, week: "1" }, { score: 88, week: "2" }, { score: 75, week: "3" }] },
      { player: "Fábio Ribeiro", team: "FC Drinkwater", total_points: 0, status: "active", journeys: [{ score: 59, week: "1" }, { score: 88, week: "2" }, { score: 63, week: "3" }] },
      { player: "Julio Pinto", team: "Maureles UTD", total_points: 0, status: "active", journeys: [{ score: 73, week: "1" }, { score: 63, week: "2" }, { score: 46, week: "3" }] },
      { player: "Sérgio Silva", team: "Ajax Limpa-Tudo", total_points: 0, status: "active", journeys: [{ score: 88, week: "1" }, { score: 62, week: "2" }, { score: 45, week: "3" }] },
      { player: "Miguel Monteiro", team: "mike Monteiro", total_points: 0, status: "active", journeys: [{ score: 80, week: "1" }, { score: 41, week: "2" }, { score: 55, week: "3" }] },
      { player: "Tiago Sousa", team: "1893", total_points: 0, status: "active", journeys: [{ score: 62, week: "1" }, { score: 61, week: "2" }, { score: 41, week: "3" }] },
      { player: "Bessa Joao", team: "joaobessa", total_points: 0, status: "active", journeys: [{ score: 52, week: "1" }, { score: 56, week: "2" }, { score: 53, week: "3" }] },
      { player: "Rafael Teixeira", team: "Medallo FC", total_points: 0, status: "active", journeys: [{ score: 69, week: "1" }, { score: 60, week: "2" }, { score: 48, week: "3" }] },
      { player: "Joel Machado", team: "Recebaaaa", total_points: 0, status: "active", journeys: [{ score: 65, week: "1" }, { score: 59, week: "2" }, { score: 47, week: "3" }] },
      { player: "Marcos Queirós", team: "O 3 Pernas", total_points: 0, status: "active", journeys: [{ score: 64, week: "1" }, { score: 68, week: "2" }, { score: 49, week: "3" }] },
      { player: "José Nuno Ferreira", team: "SR MÁRIO SARAIVA", total_points: 0, status: "active", journeys: [{ score: 62, week: "1" }, { score: 58, week: "2" }, { score: 38, week: "3" }] },
      { player: "helder ribeiro", team: "tangerinamecanica", total_points: 0, status: "active", journeys: [{ score: 56, week: "1" }, { score: 81, week: "2" }, { score: 62, week: "3" }] },
      { player: "Zé Carlos Barros", team: "Tripé", total_points: 0, status: "active", journeys: [{ score: 69, week: "1" }, { score: 44, week: "2" }, { score: 51, week: "3" }] },
      { player: "João Aguiar", team: "Bairros FC", total_points: 0, status: "active", journeys: [{ score: 63, week: "1" }, { score: 75, week: "2" }, { score: 45, week: "3" }] },
      { player: "hugo teixeira", team: "Nunez+10", total_points: 0, status: "active", journeys: [{ score: 57, week: "1" }, { score: 58, week: "2" }, { score: 47, week: "3" }] },
      { player: "Ricardo Silva", team: "Silva", total_points: 0, status: "active", journeys: [{ score: 61, week: "1" }, { score: 57, week: "2" }, { score: 55, week: "3" }] },
      { player: "Vincent Rios", team: "Guiri", total_points: 0, status: "active", journeys: [{ score: 61, week: "1" }, { score: 48, week: "2" }, { score: 70, week: "3" }] },
      { player: "Manuel Ferreira", team: "Tenho Sentimentos FC", total_points: 0, status: "active", journeys: [{ score: 53, week: "1" }, { score: 53, week: "2" }, { score: 48, week: "3" }] },
      { player: "Hugo Teixeira", team: "tnt", total_points: 0, status: "active", journeys: [{ score: 61, week: "1" }, { score: 56, week: "2" }, { score: 57, week: "3" }] },
      { player: "José Francisco Sousa", team: "Borussia Notre-Dame", total_points: 0, status: "active", journeys: [{ score: 59, week: "1" }, { score: 69, week: "2" }, { score: 62, week: "3" }] },
      { player: "João Cabral", team: "Tapadinha City F.C", total_points: 0, status: "active", journeys: [{ score: 69, week: "1" }, { score: 41, week: "2" }, { score: 62, week: "3" }] },
      { player: "Tiago Nogueira", team: "TiagoTiago", total_points: 0, status: "active", journeys: [{ score: 78, week: "1" }, { score: 53, week: "2" }, { score: 55, week: "3" }] },
      { player: "Tony Ferguson", team: "Arsenal top two LOL", total_points: 0, status: "active", journeys: [{ score: 69, week: "1" }, { score: 52, week: "2" }, { score: 52, week: "3" }] },
      { player: "Márcio Teixeira", team: "No Room For Maguires", total_points: 0, status: "active", journeys: [{ score: 49, week: "1" }, { score: 50, week: "2" }, { score: 63, week: "3" }] },
      { player: "Bruno Teixeira", team: "Quim Portuga", total_points: 0, status: "active", journeys: [{ score: 61, week: "1" }, { score: 67, week: "2" }, { score: 43, week: "3" }] },
      { player: "João Soares", team: "PepSemTola", total_points: 0, status: "active", journeys: [{ score: 64, week: "1" }, { score: 65, week: "2" }, { score: 46, week: "3" }] },
      { player: "José Luís Sousa", team: "Arsoles FC", total_points: 0, status: "inactive", journeys: [{ score: 60, week: "1" }] },
      { player: "José Mário Lopes Martins", team: "7831XF F.C.", total_points: 0, status: "active", journeys: [{ score: 62, week: "1" }, { score: 55, week: "2" }, { score: 51, week: "3" }] },
      { player: "Arlindo Vasconcelos", team: "AMRV Unt", total_points: 0, status: "active", journeys: [{ score: 68, week: "1" }, { score: 68, week: "2" }, { score: 47, week: "3" }] },
      { player: "Bernardo Neto", team: "BN", total_points: 0, status: "active", journeys: [{ score: 68, week: "1" }, { score: 56, week: "2" }, { score: 61, week: "3" }] },
      { player: "Artur Sousa", team: "Arturito", total_points: 0, status: "active", journeys: [{ score: 51, week: "1" }, { score: 57, week: "2" }, { score: 67, week: "3" }] },
      { player: "Eduardo Vieira", team: "NorwegianForce", total_points: 0, status: "active", journeys: [{ score: 65, week: "1" }, { score: 0, week: "2" }, { score: 33, week: "3" }] },
      { player: "Nuno Ferreira", team: "Era uma vez FC", total_points: 0, status: "active", journeys: [{ score: 67, week: "1" }, { score: 51, week: "2" }, { score: 53, week: "3" }] },
      { player: "José Barão", team: "Muchachos", total_points: 0, status: "active", journeys: [{ score: 47, week: "1" }, { score: 81, week: "2" }, { score: 36, week: "3" }] },
      { player: "Filipe Coutinho", team: "ghost1nteam", total_points: 0, status: "active", journeys: [{ score: 59, week: "1" }, { score: 87, week: "2" }, { score: 57, week: "3" }] },
      { player: "João Monteiro", team: "VARZIELA FC", total_points: 0, status: "active", journeys: [{ score: 68, week: "1" }, { score: 80, week: "2" }, { score: 44, week: "3" }] },
      { player: "Rui Barros", team: "Rb75", total_points: 0, status: "active", journeys: [{ score: 61, week: "1" }, { score: 43, week: "2" }, { score: 50, week: "3" }] },
      { player: "João Carneiro", team: "Carnieri2", total_points: 0, status: "active", journeys: [{ score: 61, week: "1" }, { score: 73, week: "2" }, { score: 35, week: "3" }] },
      { player: "Bruno Moreira", team: "Raul De Tomas", total_points: 0, status: "active", journeys: [{ score: 68, week: "1" }, { score: 75, week: "2" }, { score: 38, week: "3" }] },
      { player: "Eugénio Sousa", team: "OrnitologoPortista", total_points: 0, status: "active", journeys: [{ score: 51, week: "1" }, { score: 46, week: "2" }, { score: 55, week: "3" }] },
      { player: "José Ferreira", team: "Sara Jay-Jay Okocha", total_points: 0, status: "active", journeys: [{ score: 68, week: "1" }, { score: 52, week: "2" }, { score: 52, week: "3" }] },
      { player: "Rui Diogo", team: "Sanusi ZM", total_points: 0, status: "inactive", journeys: [] },
      { player: "Luis Rodrigues", team: "Change Name", total_points: 0, status: "active", journeys: [{ score: 64, week: "1" }, { score: 66, week: "2" }, { score: 35, week: "3" }] },
      { player: "Jackie Hughes", team: "UnionJackie", total_points: 0, status: "inactive", journeys: [] },
      { player: "Fábio Sanhudo", team: "Brigate_Fs1893", total_points: 0, status: "active", journeys: [{ score: 67, week: "1" }, { score: 84, week: "2" }, { score: 42, week: "3" }] },
      { player: "Miguel Moreira", team: "Atlético de Marte", total_points: 0, status: "active", journeys: [{ score: 56, week: "1" }, { score: 68, week: "2" }, { score: 49, week: "3" }] },
      { player: "Miguel Monteiro", team: "Estou só a ver", total_points: 0, status: "inactive", journeys: [{ score: 64, week: "1" }] },
      { player: "José Pedro Queiroz", team: "Titan Team", total_points: 0, status: "active", journeys: [{ score: 49, week: "1" }, { score: 56, week: "2" }, { score: 49, week: "3" }] },
      { player: "Pedro Santana", team: "Cleveland Browns", total_points: 0, status: "active", journeys: [{ score: 66, week: "1" }, { score: 55, week: "2" }, { score: 45, week: "3" }] },
      { player: "Joao Santos", team: "partemtudo", total_points: 0, status: "active", journeys: [{ score: 45, week: "1" }, { score: 58, week: "2" }, { score: 53, week: "3" }] },
      { player: "Rúben Ferraz", team: "Ruben10", total_points: 0, status: "active", journeys: [{ score: 62, week: "1" }, { score: 54, week: "2" }, { score: 36, week: "3" }] },
      { player: "Valter Pinto", team: "ValterPinto20", total_points: 0, status: "active", journeys: [{ score: 70, week: "1" }, { score: 48, week: "2" }, { score: 65, week: "3" }] },
      { player: "Leonardo Moreira", team: "Bayern da Esperança", total_points: 0, status: "inactive", journeys: [] },
      { player: "André Monteiro", team: "NWN", total_points: 0, status: "active", journeys: [{ score: 73, week: "1" }, { score: 47, week: "2" }, { score: 48, week: "3" }] },
      { player: "Rui Pereira", team: "RP32", total_points: 0, status: "active", journeys: [{ score: 55, week: "1" }, { score: 63, week: "2" }, { score: 43, week: "3" }] },
      { player: "Ângelo Santana", team: "JOHNNY DOGS UTD", total_points: 0, status: "active", journeys: [{ score: 77, week: "1" }, { score: 71, week: "2" }, { score: 39, week: "3" }] },
      { player: "Paul Tait", team: "Church of Chunks", total_points: 0, status: "inactive", journeys: [{ score: 50, week: "1" }] },
      { player: "Fabio Hugo", team: "MecosFC", total_points: 0, status: "active", journeys: [{ score: 83, week: "1" }, { score: 71, week: "2" }, { score: 53, week: "3" }] },
      { player: "Daniel Sousa", team: "Welsby FC", total_points: 0, status: "active", journeys: [{ score: 73, week: "1" }, { score: 39, week: "2" }, { score: 39, week: "3" }] },
      { player: "Bruno Serra", team: "Not Found! Error 404", total_points: 0, status: "active", journeys: [{ score: 68, week: "1" }, { score: 69, week: "2" }, { score: 65, week: "3" }] },
      { player: "Mauricio Barros", team: "Ott tanak", total_points: 0, status: "active", journeys: [{ score: 68, week: "1" }, { score: 45, week: "2" }, { score: 58, week: "3" }] },
      { player: "luiggi pereira", team: "luiggi", total_points: 0, status: "active", journeys: [{ score: 59, week: "1" }, { score: 45, week: "2" }, { score: 62, week: "3" }] },
      { player: "Jorge Nogueira", team: "JEMN FC", total_points: 0, status: "active", journeys: [{ score: 59, week: "1" }, { score: 77, week: "2" }, { score: 36, week: "3" }] },
      { player: "André Ferreira", team: "Illuminati", total_points: 0, status: "active", journeys: [{ score: 63, week: "1" }, { score: 77, week: "2" }, { score: 43, week: "3" }] },
      { player: "Vitor Mendes", team: "CÔNDE FC", total_points: 0, status: "active", journeys: [{ score: 44, week: "1" }, { score: 70, week: "2" }, { score: 42, week: "3" }] },

    ]

    data.forEach((d:any) => {
      if(d.journeys.length > 0) {
        d.total_points = ((d.journeys.map(j => j.score)).reduce((a,b) => {
          return a + b
        }))
      } else {
        d.total_points = 0;
      }

      //this.db.updateTournamentPlayer('ricardo-silva', d)
      
    })
   */
}
