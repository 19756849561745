import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/shared/database.service';

@Component({
  selector: 'app-upcoming-matches',
  templateUrl: './upcoming-matches.component.html',
  styleUrls: ['./upcoming-matches.component.scss']
})
export class UpcomingMatchesComponent implements OnInit {

  ricardoSilvaCup: any[];
  ligaEuropa: any[];
  generalClassification: any[];
  championsCurrentJourney: any[];
  groupsAvailable;
  groupsAvailableLigaEuropa;
  groupsAvailableConference;
  conferenceLeague: any[];
  showImageLigaCampeoes: boolean = false;
  showImageLigaEuropa: boolean = false;

  constructor(private db: DatabaseService) {

    this.db.getListByGeneralResults('players', 'classificacao_geral.total_points', 10)
      .then((results: any) => {
        this.generalClassification = results;
      })

      this.db.getListByGeneralResults('ricardo-silva', 'total_points')
      .then((results: any) => {
        this.ricardoSilvaCup = results.filter(player => {return player.status === "active"}).slice(0,10);
      })

      this.db.getTournamentInfo('liga-europa').then((val) => {
        let groups = val[0].groups.sort((a, b) => b.total_points - a.total_points);
        this.ligaEuropa = this.groupBy2(groups);
      })

      this.db.getTournamentInfo('estrutura-liga-campeoes').then((val) => {
        this.showImageLigaCampeoes = val[0].showImage;
        let groups = val[0].groups.sort((a, b) => b.total_points - a.total_points);
        this.championsCurrentJourney = this.groupBy(groups);
      })
      this.db.getTournamentInfo('conference-league').then((val) => {
        let groups = val[0].groups.sort((a, b) => b.total_points - a.total_points);
        this.conferenceLeague = this.groupBy3(groups);

        console.log((this.groupsAvailableConference))
      })
  }

  ngOnInit(): void {
  }

  groupBy(data: any) {

    //let resultArray: any[] = [];
    let resByType = data.reduce((result: any, obj: any) => {

      (result[obj['group']] = result[obj['group']] || []).push(obj);

      return result;

    }, {});


    this.groupsAvailable = Object.keys(resByType).sort()

    return resByType;
  }

  groupBy2(data: any) {

    //let resultArray: any[] = [];
    let resByType = data.reduce((result: any, obj: any) => {

      (result[obj['group']] = result[obj['group']] || []).push(obj);

      return result;

    }, {});


    this.groupsAvailableLigaEuropa = Object.keys(resByType).sort()

    return resByType;
  }

  groupBy3(data: any) {

    //let resultArray: any[] = [];
    let resByType = data.reduce((result: any, obj: any) => {

      (result[obj['group']] = result[obj['group']] || []).push(obj);

      return result;

    }, {});


    this.groupsAvailableConference = Object.keys(resByType).sort()

    return resByType;
  }


}
